import { ApplicationController } from './application_controller';

export default class extends ApplicationController {
  static values = { copy: String }
  static targets = [ 'icon', 'text']

  copy() {
    navigator.clipboard.writeText(this.copyValue)
    this.switchText('Copied!')
    this.switchIcon('check_circle')

    setTimeout(() => {
      this.switchText('Copy Emails')
      this.switchIcon('copy')
    }, 4000)
  }

  switchText(textValue) {
    this.textTarget.textContent = textValue
  }

  switchIcon(iconValue) {
    if (!this.hasIconTarget) {
      return;
    }
    var iconURL = this.iconTarget.getAttributeNS('http://www.w3.org/1999/xlink', 'href');
    var parts = iconURL.split('#');
    iconURL = parts[0] + '#' + iconValue;
    this.iconTarget.setAttributeNS('http://www.w3.org/1999/xlink', 'href', iconURL);
  }
}
