import { ApplicationController } from './application_controller';

export default class extends ApplicationController {
  static targets = ["crosssellpurchase", "totalcost", "totalshipping", "todaycost", "firstcost", "addworkbook", 
                    "addedworkbook", "addjournal", "addedjournal", "costjournal", "costworkbook", "regfeecost", 
                    "scholarship-toggle", "workbookpurchase", "displaypaytoday", "displaypayfirstmeeting",
                    "totaltext", 'groupshipping', 'displaygroupasterisk', 'displayregfeeasterisk', 'disclaimerjournal',
                    "currency" ];

  static currency = this.currencyTarget

  connect() { this.set_total() }

  addworkbook() {
    this.addworkbookTarget.classList.add("tw--hidden")
    this.addedworkbookTarget.classList.remove("tw--hidden")
    this.costworkbookTarget.classList.remove("tw--hidden")

    this.costworkbookTarget.dataset.cost = this.costworkbookTarget.dataset.workbookcost
    this.costworkbookTarget.dataset.shipping = 'true'

    this.set_total()
    this.workbookpurchaseTarget.value = 'true';
  }

  addjournal() {
    this.addjournalTarget.classList.add("tw--hidden")
    this.addedjournalTarget.classList.remove("tw--hidden")
    this.costjournalTarget.classList.remove("tw--hidden")
    this.disclaimerjournalTarget.classList.remove("tw--hidden")

    this.costjournalTarget.dataset.cost = this.costjournalTarget.dataset.crosssellcost
    this.costjournalTarget.dataset.today = this.costjournalTarget.dataset.crosssellcost
    this.costjournalTarget.dataset.shipping = 'true'

    this.set_total()
    this.crosssellpurchaseTarget.value = 'true';
  }

  addedworkbook() {
    this.addworkbookTarget.classList.remove("tw--hidden")
    this.addedworkbookTarget.classList.add("tw--hidden")
    this.costworkbookTarget.classList.add("tw--hidden")

    this.costworkbookTarget.dataset.cost = 0
    this.costworkbookTarget.dataset.shipping = 'false'

    this.set_total()
    this.workbookpurchaseTarget.value = 'false';
  }

  addedjournal() {
    this.addjournalTarget.classList.remove("tw--hidden")
    this.addedjournalTarget.classList.add("tw--hidden")
    this.costjournalTarget.classList.add("tw--hidden")
    this.disclaimerjournalTarget.classList.add("tw--hidden")

    this.costjournalTarget.dataset.cost = 0
    this.costjournalTarget.dataset.today = 0
    this.costjournalTarget.dataset.shipping = 'false'

    this.set_total()

    this.crosssellpurchaseTarget.value = 'false';
  }

  set_total() {
    if ( !this.hasCurrencyTarget ) {
      return;
    }
    var currency = this.currencyTarget.dataset.currency
    var cost = document.getElementsByClassName("cost-calculation")
    var total = 0.00
    var first = 0.00
    var today = 0.00
    var shipping = false

    for (var i = 0; i < cost.length; i++) {
      total += parseFloat( cost.item(i).dataset.cost )
      if ( cost.item(i).dataset.shipping == "true" ) {
        shipping = true
      }
      if ( cost.item(i).dataset.today ) {
        today += parseFloat( cost.item(i).dataset.cost )
      }
      if ( cost.item(i).dataset.first ) {
        first += parseFloat( cost.item(i).dataset.cost )
      }
    }


    this.displaygroupasteriskTarget.classList.add('tw--hidden')

    this.displayregfeeasteriskTarget.classList.add('tw--hidden')

    if ( shipping == true ) {
      this.totalshippingTarget.classList.remove("tw--hidden") 
    } else {
      this.totalshippingTarget.classList.add("tw--hidden") 
    }

    if ( total > 0 ) {
      if ( today > 0 ) {
        this.todaycostTarget.innerHTML = currency + today.toFixed(2)
        this.displaypaytodayTarget.classList.remove("tw--hidden") 
      } else {
        this.todaycostTarget.innerHTML = currency + "0.00"
        this.displaypaytodayTarget.classList.add("tw--hidden") 
      }
      if ( first > 0 ) {
        this.firstcostTarget.innerHTML = currency + first.toFixed(2)
        this.displaypayfirstmeetingTarget.classList.remove("tw--hidden") 
      } else {
        this.firstcostTarget.innerHTML = currency + "0.00"
        this.displaypayfirstmeetingTarget.classList.add("tw--hidden")
      }
    } else {
      this.todaycostTarget.innerHTML = currency + "0.00"
    }

    if ( total > 0 ) {
      var added_class = 'tw--text-brand-primary'
      var removed_class = 'tw--text-primary'

      if (total == first ) {
        this.totaltextTarget.innerHTML = "Pay at First Meeting"
        this.displaypayfirstmeetingTarget.classList.add("tw--hidden")

      } else if (total == today) {
        this.displaypaytodayTarget.classList.add("tw--hidden")

      } else {
        this.totaltextTarget.innerHTML = "Total"
        added_class = 'tw--text-primary'
        removed_class = 'tw--text-brand-primary'

        this.displaygroupasteriskTarget.classList.remove('tw--hidden')
        this.displayregfeeasteriskTarget.classList.remove('tw--hidden')
      }

      this.totaltextTarget.classList.add(added_class)
      this.totalcostTarget.classList.add(added_class)
      this.totaltextTarget.classList.remove(removed_class)
      this.totalcostTarget.classList.remove(removed_class)
    }

    this.totalcostTarget.innerHTML = currency + total.toFixed(2)
  }



  set_checkout_buttons(checkout, register) {
    var checkout_list = this.checkoutTarget.classList
    var register_list = this.registerTarget.classList

    checkout ? checkout_list.remove('tw--hidden') : checkout_list.add('tw--hidden')
    register ? register_list.remove('tw--hidden') : register_list.add('tw--hidden')
  }
}