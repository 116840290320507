import { ApplicationController } from './application_controller';
const TomSelect = require('tom-select')

export default class extends ApplicationController {
  static targets = [
    'meeting'
  ];

  connect() {
    const meetingNames = this.meetingTarget.dataset.name
    var remaining_sessions = []

    var control = new TomSelect(this.element,{
      onItemRemove:function( number ) {
        remaining_sessions.push({name: number, title: number})
      },

      onInitialize:function(){
        if ( meetingNames != '' ) {
          try {
            const test = JSON.parse(meetingNames.trim());
            const west = test.sort((a, b) =>
                a.match(/\d+/) - b.match(/\d+/) ||
                a.localeCompare(b)
            );

            this.setValue(west);

          } catch (err) {
            const test = meetingNames.split(',');
            this.setValue(test);
          }
        } else {
          this.setValue('');
        }
      },

      onFocus:function(){
        var new_opts = []

        $(".item").each(function(){
          var input = $(this); 
          var itemValue = input[0].innerText.slice(0, -2)
          new_opts.push(itemValue);
        });

        var default_session_options = [
          "Session 1", "Session 2", "Session 3", "Session 4", 
          "Session 5", "Session 6", "Session 7", "Session 8",
          "Session 9", "Session 10", "Session 11", "Session 12", 
          "Session 13"
        ]
      },

      maxItems: 2,
      valueField: 'name',
      labelField: 'name',
      placeholder: '',
      styleField: '',
      
      options: [
        {name: "Session 1", title: 'Session 1'},
        {name: "Session 2", title: 'Session 2'},
        {name: "Session 3", title: 'Session 3'},
        {name: "Session 4", title: 'Session 4'},
        {name: "Session 5", title: 'Session 5'},
        {name: "Session 6", title: 'Session 6'},
        {name: "Session 7", title: 'Session 7'},
        {name: "Session 8", title: 'Session 8'},
        {name: "Session 9", title: 'Session 9'},
        {name: "Session 10", title: 'Session 10'},
        {name: "Session 11", title: 'Session 11'},
        {name: "Session 12", title: 'Session 12'},
        {name: "Session 13", title: 'Session 13'}
      ],

      create: false,
      plugins: { 
        remove_button:{
          title:'Remove this item',
        }
      }
    });
  }
}
